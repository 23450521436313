<template>
    <PageHeader :title="title" />

    <componentCalendar />

</template>

<script>
import PageHeader from "@/components/page-header";
import componentCalendar from '@/components/calendar/index'

export default {
  data() {
    return {
      title: this.$t('Calendar'),
    }
  },
  components: {
    PageHeader,
    componentCalendar
  },
  created(){
    
  },
  methods: {
    
  },
};
</script>

