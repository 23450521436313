<template>
    <!-- 
        Модальне вікно створення події 
    -->

    <modal @close="$emit('close')">
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('addEvent') }}</template>
        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>
            <b-row>
                <!-- Назва події -->
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('eventName') }}</h4>
                        <input type="text" class="form-control" v-model="this.form.name">
                    </div>
                </b-col>

                <!-- Тип події -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('productType') }}</h4>
                        <select class="form-control" v-model="form.type" @change="searchEvent">
                            <template v-for="item in listType" :key="item" >
                                <option v-if="isShowItem(item)" :value="item.value">{{ item.title }}</option>
                            </template>
                        </select>
                    </div>
                </b-col>

                <!-- Конфіденційність події -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Privacy') }}</h4>
                        <select class="form-control" v-model="form.status">
                            <option v-for="item in listStatus" :key="item" :value="item.value">{{ item.title }}</option>
                        </select>
                    </div>
                </b-col>

                <!-- Дата події -->
                <b-col lg="6" v-if="this.form.type !== 'vacation' && this.form.type !== 'graphs' && this.form.type !== 'dayoffWithTime'">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('dateEvent') }}</h4>
                        <flatpickr v-model="date" :config="configDate" class="form-control border-0 dash-filter-picker shadow" @onChange="change"></flatpickr>
                    </div>
                </b-col>

                <!-- Дата події 
                    Відрізняється розмір поля для краси 
                -->
                <b-col lg="12" v-if="this.form.type === 'vacation' || this.form.type === 'graphs'">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('dateEvent') }}</h4>
                        <flatpickr v-model="date" :config="configDate" class="form-control border-0 dash-filter-picker shadow" @onChange="change"></flatpickr>
                    </div>
                </b-col>

                <!-- Час події -->
                <b-col lg="6" v-if="this.form.type !== 'vacation' && this.form.type !== 'graphs' && this.form.type !== 'dayoffWithTime'">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('timeEvent') }}</h4>
                        <flatpickr v-model="form.time" :config="configTime" class="form-control border-0 dash-filter-picker shadow"></flatpickr>
                    </div>
                </b-col>

                <!-- <b-row v-if="this.form.type === 'dayoffWithTime'">
                    <label>{{ $t('from') }}</label>
                    <div class="input-group" style="margin-bottom: 10px;">
                        <flatpickr v-model="form.date" :config="configWithTime" class="form-control border-0 dash-filter-picker shadow"></flatpickr>
                    </div>
                    <label>{{ $t('to') }}</label>
                    <div class="input-group">
                        <flatpickr v-model="form.toDate" :config="configWithTime" class="form-control border-0 dash-filter-picker shadow"></flatpickr>
                    </div>
                </b-row> -->

                <!-- Відображення альорту, якщо подія за вказаний часом існує -->
                <b-col v-if="searchEvent()">
                    <b-alert show variant="info" class="text-center">
                        <h5 class="alert-heading" style="margin-bottom: 0;">
                            {{ $t('alreadyInfoEvent') }}
                        </h5>
                    </b-alert>
                </b-col>

                <!-- Опис до події -->
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('description') }}</h4>
                        <textarea type="text" class="form-control" v-model="this.form.description"></textarea>
                    </div>
                </b-col>
            </b-row>
        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom >
            <div>
                <!-- 
                    Кнопка "Додати" 
                    - надає змогу створити подію
                -->
                <button 
                    type="button" 
                    v-if="this.perm === false" 
                    class="btn btn-success" 
                    v-on:click="add"
                >
                    {{ $t('Add') }}
                </button>

                <!-- 
                    Кнопка "Додати" 
                    - надає змогу відредагувати подію
                -->
                <button 
                    type="button" 
                    v-else 
                    class="btn btn-success" 
                    v-on:click="edit"
                >
                    {{ $t('Edit') }}
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog' // компонент модального вікна
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store'; // глобальне сховище даних
import { DataCalendar } from '@/API' // клас для взаємодії з API 
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new DataCalendar();

export default{
    props: ['newEventData', 'perm', 'obj', 'events'],
    components: {
        modal,
        flatpickr: Vueflatpickr
    },
    data(){
        return{
            id: '',
            form: {
                name: "", // назва події
                type: "", // тип події
                status: "", // конф події
                date: "", // дата події, 2023-05-04
                toDate: "", // 2-а дата події, 2023-05-10 , якщо вказується період
                time: "09:00", // час події, 14:00
                description: "", // опис події
                document: { // документ по цьому створена подія (відгул, презентація, завдання)
                    id: '', // id документу
                    type: '' // тип документу
                }
            },
            listStatus: [
                // Конфіденційність події
                { title: this.$t('private'), value: 'private' }, // приватний 
                { title: this.$t('ForAll'), value: 'public' }, // для всіх
            ],
            listType: [
                // Список типів подій
                { title: this.$t('presentation'), value: 'presentation', perms: '110' }, // презентація
                { title: this.$t('connectionDevice'), value: 'connection', perms: '200' }, // підключення
                { title: this.$t('Task'), value: 'task', perms: '200' }, // завдання
                { title: this.$t('Meeting'), value: 'meeting', perms: null }, // зустріч
                { title: this.$t('deadLine'), value: 'deadline', perms: '370' }, // дедлайн
                { title: this.$t('bDay'), value: 'bday', perms: null }, // день народження
                { title: this.$t('DayOff'), value: 'graphs', perms: null }, // вихідний
                { title: this.$t('Vacation'), value: 'vacation', perms: null }, // відпустка
                { title: this.$t('Other'), value: 'other', perms: null }, // інше
                { title: this.$t('private'), value: 'private', perms: null }, // приватні
                { title: this.$t('DayOffWithTime'), value: 'dayoffWithTime', perms: null }, // вихідний по часу
            ],
            date: '',
            configDate: { // параметри для flatpickr
                mode: "range",
                altInput: true,
                // altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: "",
                defaultDate: []
            },
            configTime: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                // minTime: "00:00",
                // maxTime: "23:00"
            },
            configWithTime: { // параметри налаштування Flatpickr
                mode: "singles", // параметр, який дозволяє вибрати період
                minDate: "today", // параметр, від якої дати можна обрати період
                altInput: true,
                enableTime: false, // вимкнення вказування часу
                dateFormat: "Y-m-d H:i", // формат дати
                locale: "uk", // мова 
                enableTime: true,
                time_24hr: true
            },
        }
    },
    created(){

        // Встановлення локалізації для flatpickr
        if(storeS.lang == 'uk'){
            this.configDate.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.configDate.locale = Russian
        }

        /**
            * Встановлення типу модалки та її id
            * Потрібно для сокету
        */
        storeS.checkModal.type = 'event';
        storeS.checkModal.id = Math.floor(Math.random() * (9999 - 10)) + 10;

        // Ініціалізація дати з календаря або з кліку на окремий день
        this.date = this.newEventData != '' ? String(this.newEventData) : '' 
        this.form.date = this.newEventData != '' ? String(this.newEventData) : ''

        // Заповнення форми даними для редагування події
        if(this.perm == true) {
            this.id = this.obj.id;
            this.form.name = this.obj.title;
            this.form.type = this.obj.extendedProps.type;
            this.form.status = this.obj.extendedProps.status;
            this.form.date = this.formatDate(this.obj.startStr).dateStr;
            (this.formatDate(this.obj.endStr).dateStr == 'NaN-aN-aN' ?  this.formatDate(this.obj.startStr).dateStr : this.formatDate(this.obj.endStr).dateStr)
            this.configDate.defaultDate.push(
                this.formatDate(this.obj.startStr).dateStr,
                (this.formatDate(this.obj.endStr).dateStr == 'NaN-aN-aN' ?  this.formatDate(this.obj.startStr).dateStr : this.formatDate(this.obj.endStr).dateStr)
            )
            this.form.time = this.formatDate(this.obj.startStr).timeStr;
            this.form.description = this.obj.extendedProps.description;
            this.form.document.id = this.obj.extendedProps.idOper;
            this.form.document.type = this.obj.extendedProps.type;
        }

        // this.currentDate();
    },
    methods: {
        isShowItem(option) {
            /**
                * Метод для перевірки, чи слід показувати елемент відповідно до прав доступу користувача.
                * @param {Object} option - Об'єкт з параметрами елемента.
                * @returns {boolean} - Результат перевірки на відображення елемента.
            */

            return this.perms[2000] == true || this.perms[option.perms] == true || option.perms == null;
        },
        add(){
            /**
                * Метод для додавання нової події в календар
            */

            if (!this.form.name) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            }
            if (!this.form.type) {
                //перевіряємо чи вказаний тип
                this.$toast.error(this.$t('EnterType'));
            }
            if (!this.form.date) {
                //перевіряємо чи вказана дата 
                this.$toast.error(this.$t('specifyDate'));
            }
            if (!this.form.status) {
                //перевіряємо чи вказаний конф 
                this.$toast.error(this.$t('SpecifyPrivacy'));
            }
            if (!this.form.description) {
                //перевіряємо чи вказаний опис
                this.$toast.error(this.$t('EnterDesc'));
            }
            if(this.form.date != '' && this.form.toDate == ''){
                delete this.form.toDate
            }

            if(this.form.name && this.form.type && this.form.date && this.form.status && this.form.description){
                // Виклик методу для додавання події
                apiServe.addEvent(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('Added'));
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var errorText = 'Помилка створення події'; 
                        var statusRequest = result.status;
                        var api = 'addEvent';
                        var fileRequest = 'src/components/calendar/create.vue';
                        var params = this.form;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
        edit(){
            /**
                * Метод для редагування вибраної події в календарі
            */

            if (!this.id) {
                //перевіряємо чи вказаний id акаунту
                this.$toast.error(this.$t('idNotFound'));
            }
            if (!this.form.name) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            }
            if (!this.form.description) {
                //перевіряємо чи вказаний опис
                this.$toast.error(this.$t('EnterDesc'));
            }
            if (!this.form.type) {
                //перевіряємо чи вказаний тип
                this.$toast.error(this.$t('EnterType'));
            }
            if (!this.form.date) {
                //перевіряємо чи вказана дата
                this.$toast.error(this.$t('specifyDateTime'));
            }
            if (!this.form.time) {
                //перевіряємо чи вказаний час
                this.$toast.error(this.$t('specifyDateTime'));
            }
            if (!this.form.status) {
                //перевіряємо чи вказаний статус
                this.$toast.error(this.$t('EnterStatus'));
            }
            if(this.id 
                && this.form.name
                && this.form.description
                && this.form.type
                && this.form.date
                && this.form.time
                && this.form.status
            ){
                // Виклик методу для редагування події
                apiServe.editEvent(this.id, this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('alertChanged'));
                        this.$emit('getdata')
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var tempObj = {
                            "id": this.id,
                            "params": this.form
                        }

                        var errorText = 'Помилка редагування штрафу'; 
                        var statusRequest = result.status;
                        var api = 'editEvent';
                        var fileRequest = 'src/components/calendar/create.vue';
                        var params = tempObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
        formatDate(date) {
            /**
                * Метод для форматування дати та часу.
                * @param {string} date - Рядок дати/часу для форматування.
                * @returns {Object} - Об'єкт із рядком дати та часу.
            */

            var date3 = new Date(date); 
            var dateStr = date3.getFullYear() + "-" + ("00" + (date3.getMonth() + 1)).slice(-2) + "-" + ("00" + date3.getDate()).slice(-2);
            var timeStr = ("00" + date3.getHours()).slice(-2) +":"+("00" + date3.getMinutes()).slice(-2)
            return { dateStr, timeStr};
        },
        change(selectedDates, dateStr, instance){
            /**
                * Метод, який викликається при зміні дати та часу в flatpickr.
                * @param {Array} selectedDates - Масив вибраних дат.
                * @param {string} dateStr - Рядок з вибраними датами.
                * @param {Object} instance - Екземпляр flatpickr.
            */

            var arrDate = String(dateStr)
            
            // Розділення рядка на масив, в залежності від локалізації
            if(storeS.lang == 'ru'){
                arrDate = arrDate.split(' — ')
            } else {
                arrDate = arrDate.split(' to ')
            }

            // Оновлення дати та toDate в формі
            this.form.date = arrDate[0];
            this.form.toDate = arrDate[1];
        },
        searchEvent(){
            /**
                * Метод для перевірки наявності події з обраним типом та часом.
                * @returns {boolean} - Результат пошуку події.
            */

            var status = false;
            this.events.forEach(element => {
                if(element.type == this.form.type && element.start == this.form.date+"T"+this.form.time+":00"){
                    status = true
                }
            });
            return status
        }
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        }
    }
}
</script>